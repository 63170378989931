<script>
export default {
  name: "ImageShow",
  props: {
    img: {
      type: String,
      default(){
        return ''
      }
    },
  },
  data(){
    return {

    }
  },
  computed: {
    hasImg(){
      return !!this.img.slice(5)
    },
  },
  methods: {

  }
}
</script>

<template>
  <div class="image-upload-show-container">
    <div v-if="hasImg" class="image-container"  >
      <el-image :src="img" fit="cover"
                style="width: 100%;height: 100%;"
                :preview-src-list="[img]"></el-image>
    </div>
    <div v-else class="empty-status">
      <el-empty style="transform: scale(0.5);" description="暂无图片"></el-empty>
    </div>
  </div>
</template>

<style scoped lang="scss">
.image-upload-show-container{
  position: relative;
  padding: 8px;
  width: 128px;
  height: 128px;
  background-color: #F5F7FA;
  border: 1px dotted #CED4DB;
  .image-container{
    width: 100%;
    height: 100%;
  }
  .empty-status{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    .el-icon-plus{
      color: #2F3133;
      font-size: 22px;
    }
    .prompt{
      margin-top: -8px;
      font-size: 12px;
      color: #8D9094;
    }
  }
  .delete-icon{
    position: absolute;
    right: 12px;
    top: 12px;
    width: 20px;
    height: 20px;
    img{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>