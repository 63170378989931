/**
 v-if="DS_showPageTitle"

 :style="DS_showInfoPadding({}, {padding: '0'})"

 :style="DS_showInfoPadding({padding: '20px'}, {padding: '20px 0 0'})"

 mixins: [detailsShow],

 */
export const detailsShow = {
    props: {
        // 标题展示
        DS_showPageTitle: {
            type: Boolean,
            default(){
                return true
            }
        },
        // 布局样式
        DS_showInfoStyle:{
            type: Boolean,
            default(){
                return false
            }
        },
    },
    methods: {
        DS_showInfoPadding(style, newStyle){
            if (!this.DS_showInfoStyle) {
                return style
            }
            return newStyle
        },
    }
}
