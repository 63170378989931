<script>
export default {
  name: "ImageShows",
  props: {
    imgs: {
      type: Array,
      default(){
        return []
      }
    },
  },
  data(){
    return {

    }
  },
  computed: {

  },
  methods: {

  }
}
</script>

<template>
  <div class="image-upload-show-container">
    <div v-for="iter in imgs" :key="iter" class="image-container"  >
      <el-image :src="iter" fit="cover"
                style="width: 100%;height: 100%;"
                :preview-src-list="imgs"></el-image>
    </div>
  </div>
</template>

<style scoped lang="scss">
.image-upload-show-container{
  display: flex;
  flex-wrap: wrap;
  background-color: #ffffff;

  .image-container{
    padding: 8px;
    margin-right: 10px;
    border: 1px dotted #CED4DB;
    width: 128px;
    height: 128px;
    background-color: #F5F7FA;
  }
  .empty-status{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    .el-icon-plus{
      color: #2F3133;
      font-size: 22px;
    }
    .prompt{
      margin-top: -8px;
      font-size: 12px;
      color: #8D9094;
    }
  }
}
</style>